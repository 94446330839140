import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Link from "../../Link";
import Image from "../../Image";
import Translation from "../../Translation";
import Separator from "../Separator";

const GileadFooter: FunctionComponent = () => {
  const data = useStaticQuery(
    graphql`
      query {
        gilead: file(relativePath: { eq: "logos/gilead.png" }) {
          ...SmallImage
        }
      }
    `
  );

  return (
    <div className="gilead bg-black text-white">
      <div className="container mx-auto p-4">
        <div className="flex flex-col laptop:flex-row w-full items-start laptop:items-center py-4">
          <div className="self-start">
            <Link external href="http://www.gilead.com">
              <Image
                className="logo"
                fluid={data.gilead.childImageSharp.fluid}
                alt="Gilead Logo"
                width={187}
              />
            </Link>
          </div>
          <div className="py-4 laptop:py-0 laptop:px-4">
            <Link
              external
              href="http://www.gilead.com/privacy/website-privacy-statement"
              target="_blank"
              analytics="'External Links','Clicked','Privacy Policy','Interaction','Company Information'"
              className="inline-block px-1 laptop:px-4 font-bold underline laptop:font-normal laptop:no-underline"
            >
              <Translation id="privacy-policy" />
            </Link>
            <Separator />
            <Link
              external
              href="http://www.gilead.com/terms"
              target="_blank"
              analytics="'External Links','Clicked','Terms of Use','Interaction','Company Information'"
              className="inline-block px-1 laptop:px-4 font-bold underline laptop:font-normal laptop:no-underline"
            >
              <Translation id="terms-of-use" />
            </Link>
            <Separator />
            <Link
              external
              href="http://www.gilead.com/contact"
              target="_blank"
              analytics="'External Links','Clicked','Contact','Interaction','Company Information'"
              className="inline-block px-1 laptop:px-4 font-bold underline laptop:font-normal laptop:no-underline"
            >
              <Translation id="contact" />
            </Link>
            <Separator />
            <Link
              external
              href="http://www.gilead.com/"
              target="_blank"
              analytics="'External Links','Clicked','About','Interaction','Company Information'"
              className="inline-block px-1 laptop:px-4 font-bold underline laptop:font-normal laptop:no-underline"
            >
              <Translation id="about-us" />
            </Link>
          </div>
        </div>
        <div className="content pb-4 w-full">
          <p>
            <Translation id="gilead-trademarks" />
          </p>
          <p>
            <Translation id="other-trademarks" />
          </p>
          <p>
            <Translation id="copyright" /> <Translation id="partcode" />{" "}
            <Translation id="approval-date" />
          </p>
          <p>
            <Translation id="this-gilead-website" />
          </p>
        </div>
      </div>
    </div>
  );
};
export default GileadFooter;
